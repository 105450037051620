//** State Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getEnvironment = createAsyncThunk('EnvironmentSlice/getEnvironment', async () => {
    try {
        let response = await axios.get('/environment', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.error('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEnvironment = createAsyncThunk('EnvironmentSlice/createEnvironment', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/environment', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'State added succesfully.');
        await dispatch(getEnvironment());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.error('err', err);
        return Promise.reject(err);
    }
});

export const updateEnvironment = createAsyncThunk('EnvironmentSlice/updateEnvironment', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/environment/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'State updated succesfully.');
        await dispatch(getEnvironment());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.error('err', err);
        return Promise.reject(err);
    }
});

export const deleteEnvironment = createAsyncThunk('EnvironmentSlice/deleteEnvironment', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/environment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'State deleted successfully.');
        await dispatch(getEnvironment());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.error('err', err);
        return Promise.reject(err);
    }
});

export const getEnvironmentById = createAsyncThunk('EnvironmentSlice/getEnvironmentById', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.get('/environment/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.error('err', err);
        return Promise.reject(err);
    }
});

export const updateEnvironmentStatus = createAsyncThunk('EnvironmentSlice/updateEnvironmentStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/environment/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || ' Status updated successfully !');
        await dispatch(getEnvironment());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.error('err', err);
        return Promise.reject(err);
    }
});

export const environmentSlice = createSlice({
    name: 'EnvironmentSlice',
    initialState: {
        environments: []
    },
    extraReducers: (builder) => {
        builder.addCase(getEnvironment.fulfilled, (state, action) => {
            state.environments = action?.payload;
            return;
        });
    }
});

export default environmentSlice.reducer;
