//** State Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// Loader Components
import showLoader from '@components/loader';

export const getAppVersion = createAsyncThunk('appVersionSlice/getAppVersion', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/app-version?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVersionByType = createAsyncThunk('appVersionSlice/getVersionByType', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/app-latest-version-type?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLatestVersion = createAsyncThunk('appVersionSlice/getLatestVersion', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/app-latest-version?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAppVersion = createAsyncThunk('appVersionSlice/createAppVersion', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/app-version', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'app Version added successfully.');

        const formData = {
            environmentId: data.environmentId,
            platform: data.platform
        };
        dispatch(getAppVersion(formData));

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        console.error('error', err);
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAppVersionStatus = createAsyncThunk('appVersionSlice/updateAppVersionStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/app-version/status/' + data?.id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || ' Status updated successfully !');
        const formData = {
            environmentId: data.environmentId,
            platform: data.platform
        };
        dispatch(getAppVersion(formData));
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.error('error', err);
        return Promise.reject(err);
    }
});

export const updateAppVersion = createAsyncThunk('appVersionSlice/updateAppVersion', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/app-version/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'App Version updated successfully.');
        const formData = {
            environmentId: data.data.environmentId,
            platform: data.data.platform
        };
        dispatch(getAppVersion(formData));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const appVersionSlice = createSlice({
    name: 'appVersionSlice',
    initialState: {
        data: [],
        latestVersion: [],
        latestVersionType: []
    },

    extraReducers: (builder) => {
        builder.addCase(getAppVersion.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getLatestVersion.fulfilled, (state, action) => {
            state.latestVersion = action?.payload;
            return;
        });
        builder.addCase(getVersionByType.fulfilled, (state, action) => {
            state.latestVersionType = action?.payload;
            return;
        });
    }
});

export default appVersionSlice.reducer;
