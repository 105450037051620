// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import moduleSlice from '../views/Setup/ModuleManage/store';
import environmentSlice from '../views/Environment/store';
import appVersionSlice from '../views/AppVersion/store';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    moduleSlice,
    environment: environmentSlice,
    appVersion: appVersionSlice
};

export default rootReducer;
